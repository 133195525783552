// This page is specific to Georgia facilities.

import React, { useEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { resetKeyboard, setInput } from "../../../redux/Keyboard/keyboard.action";
import BackButton from "../../shared/BackButton";
import {
  setDesignatedAgent,
  unitObject,
} from "../../Utils/Common";

const DesignatedAgent = (props) => {
  const dispatch = useDispatch();
  const formEl = useRef(null);

  // const account = contactObject() || {};
  const [submitting, setSubmitting] = useState(false);
  const { currentInput, currentValue } = useSelector((state) => state.keyboard);

  if (unitObject() === null) {
    props.history.push("/");
  }

  const capitalize = (s) => {
    if (s.length === 0) return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const formik = useFormik({
    initialValues: {
      agent_name: "",
      agent_email: "",
      agent_address: "",
      agent_state: "",
      agent_city: "",
      agent_zip: "",
    },
    onSubmit: (values) => {},
    validationSchema: Yup.object().shape({
      agent_name: Yup.string().required("Name required"),
      agent_email: Yup.string().email("Please enter correct Email").required("Email required"),
      agent_address: Yup.string().max(140).required("Address required"),
      agent_state: Yup.string()
        .matches(
          /^(AK|AL|AR|AZ|CA|CO|CT|DC|DE|FL|GA|HI|IA|ID|IL|IN|KS|KY|LA|MA|MD|ME|MI|MN|MO|MS|MT|NB|NC|ND|NH|NJ|NM|NV|NY|OH|OK|OR|PA|RI|SC|SD|TN|TX|UT|VA|VT|WA|WI|WV|WY)$/,
          "State must be valid"
        )
        .required("State required"),
      agent_city: Yup.string().required("City required"),
      agent_zip: Yup.string().matches(/\d{5}/, "Zip Code must be 5 digits").required("Zip required"),
    }),
  });

  useEffect(() => {
    dispatch(
      resetKeyboard([
        "agent_name",
        "agent_email",
        "agent_address",
        "agent_city",
        "agent_state",
        "agent_zip",
        "submit",
      ])
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentInput === "agent_state") {
      formik.setFieldValue(currentInput, currentValue.toUpperCase(), true);
    } else if (currentInput === "agent_name" || currentInput === "agent_city") {
      formik.setFieldValue(currentInput, capitalize(currentValue), true);
    } else {
      formik.setFieldValue(currentInput, currentValue, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentInput, currentValue]);

  const handleFocus = (inputName) => {
    const value = formik.values[inputName];
    dispatch(setInput(inputName, value || ""));
    setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 300);
  };

  const handleState = (e) => {
    e.target.value = e.target.value.toUpperCase();
    formik.handleChange(e);
  };

  const handleCity = (e) => {
    e.target.value = capitalize(e.target.value);
    formik.handleChange(e);
  };

  const handleNames = (e) => {
    e.target.value = capitalize(e.target.value);
    formik.handleChange(e);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formik.isValid) {
      formik.setTouched({
        agent_name: true,
        agent_email: true,
        agent_address: true,
        agent_state: true,
        agent_city: true,
        agent_zip: true,
      });
      return;
    }
    setSubmitting(true);
    setDesignatedAgent(formik.values);
    props.history.push("/military");
    setSubmitting(false);
  };

  return (
    <React.Fragment>
      <div className="wrapper wrapper-flex">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <div className="text-center">
                <h1 className="font-weight-bold mb-5">
                  Designated Agent
                </h1>
              </div>
            </div>
          </div>
          <form ref={formEl} className="accountInfo" onSubmit={handleSubmit} autoComplete="off">
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <input
                    autoComplete="off"
                    type="text"
                    className={`form-control form-control-lg ${currentInput === "agent_name" ? "focus" : ""}`}
                    placeholder="Name"
                    name="agent_name"
                    value={formik.values.agent_name}
                    onChange={handleNames}
                    onBlur={formik.handleBlur}
                    onFocus={() => handleFocus("agent_name")}
                  />
                  <span className="text-danger">{formik.touched.agent_name && formik.errors.agent_name}</span>
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <input
                    type="text"
                    className={`form-control form-control-lg ${currentInput === "agent_email" ? "focus" : ""}`}
                    placeholder="Email Address"
                    autoComplete="agent_email"
                    name="agent_email"
                    value={formik.values.agent_email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onFocus={() => handleFocus("agent_email")}
                  />
                  <span className="text-danger">{formik.touched.agent_email && formik.errors.agent_email}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <input
                    type="text"
                    className={`form-control form-control-lg ${currentInput === "agent_address" ? "focus" : ""}`}
                    placeholder="Address"
                    autoComplete="agent_address"
                    name="agent_address"
                    value={formik.values.agent_address}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onFocus={() => handleFocus("agent_address")}
                  />
                  <span className="text-danger">{formik.touched.agent_address && formik.errors.agent_address}</span>
                </div>
              </div>

              <div className="col-5">
                <div className="form-group">
                  <input
                    type="text"
                    className={`form-control form-control-lg ${currentInput === "agent_city" ? "focus" : ""}`}
                    placeholder="City"
                    autoComplete="agent_city"
                    name="agent_city"
                    value={formik.values.agent_city}
                    onChange={handleCity}
                    onBlur={formik.handleBlur}
                    onFocus={() => handleFocus("agent_city")}
                  />
                  <span className="text-danger">{formik.touched.agent_city && formik.errors.agent_city}</span>
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <input
                    type="text"
                    className={`form-control form-control-lg ${currentInput === "agent_state" ? "focus" : ""}`}
                    maxLength="2"
                    placeholder="State"
                    autoComplete="agent_state"
                    name="agent_state"
                    value={formik.values.agent_state}
                    onChange={handleState}
                    onBlur={formik.handleBlur}
                    onFocus={() => handleFocus("agent_state")}
                  />
                  <span className="text-danger">{formik.touched.agent_state && formik.errors.agent_state}</span>
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <input
                    type="text"
                    className={`form-control form-control-lg ${currentInput === "agent_zip" ? "focus" : ""}`}
                    placeholder="Zip"
                    autoComplete="agent_zip"
                    name="agent_zip"
                    maxLength="5"
                    value={formik.values.agent_zip}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onFocus={() => handleFocus("agent_zip")}
                  />
                  <span className="text-danger">{formik.touched.agent_zip && formik.errors.agent_zip}</span>
                </div>
              </div>
            </div>
            
            <div className="row mt-4">
              <div className="col-12 text-right">
                <button
                  name="submit"
                  type="submit"
                  disabled={submitting || formik.isSubmitting}
                  className={`btn btn-pink btn-lg letter-spacing-2 ${currentInput === "submit" ? "focus" : ""}`}
                >
                  {submitting ? "Submitting" : "Next"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <BackButton backPath="/account-information" />
    </React.Fragment>
  );
};

export default DesignatedAgent;
