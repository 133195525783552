import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { locationCode, setSessionObject, setTenantProfile } from "../../../Utils/Common";
import { getTenantProfile } from "../../../Utils/data";
import BackButton from "../../../shared/BackButton";
import { validateTenantLeaseFiles } from "../../../services/lease";
import { setInput, resetKeyboard } from "../../../../redux/Keyboard/keyboard.action";

function TenantLogin(props) {
  const [lastName, setLastName] = useState("");
  const [unitNo, setUnitNo] = useState("");
  const [last4DigitsOfPhoneNumber, setLast4DigitsOfPhoneNumber] = useState("");
  const [fourDigitsPhoneNumberFieldTouched, set4DigitsPhoneNumberFieldTouched] = useState(false);
  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();

  const { currentInput, currentValue } = useSelector((state) => state.keyboard);

  useEffect(() => {
    dispatch(resetKeyboard(["last_name", "unit_no", "last_4_digits_of_phone_number", "submit"]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentInput === "last_name") setLastName(currentValue);
    if (currentInput === "unit_no") setUnitNo(currentValue);
    if (currentInput === "last_4_digits_of_phone_number") setLast4DigitsOfPhoneNumber(currentValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentInput, currentValue]);

  const handleFocus = (inputName, e) => {
    dispatch(setInput(inputName, e.target.value));
    setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 300);
  };

  const setSession = async (user) => {
    const tenantProfile = await getTenantProfile(locationCode(), user.id);
    setTenantProfile(tenantProfile);
    setSessionObject(user);
    // props.history.push("/my-units");
    validateTenantLeaseFiles(locationCode(), user.id, "/my-units");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const data = {
      location_code: locationCode(),
      unit_number: unitNo,
      last_name: lastName,
      last_4_digits_of_phone_number: last4DigitsOfPhoneNumber,
    };
    axios
      .post("/api/ssm/tenant_lookup", JSON.stringify(data), {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        if (response.data.message === "success") {
          setSession(response.data.user);
        } else {
          setError("Please enter correct information.");
        }
        setSubmitting(false);
      });
  };

  return (
    <div className="wrapper wrapper-flex">
      <div className="container">
        <div className="text-center">
          <h1 className="font-weight-bold">My Account</h1>
          <p className="mt-3">
            Enter your Last Name, the last 4 digits of the phone number on your account, and your Unit Number exactly as
            it appears on your lease and invoices.
            <br />
            Tip: Some units have "0" as a prefix, e.g. 0014
            <br />
          </p>
          <p className="text-danger">{error}</p>
        </div>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <div className="row py-2">
            <div className="col-md-8 mx-auto">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Last Name"
                  autoComplete="off"
                  name="last_name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  onFocus={(e) => handleFocus("last_name", e)}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Last 4 digits of your phone number"
                  autoComplete="off"
                  name="last_4_digits_of_phone_number"
                  value={last4DigitsOfPhoneNumber}
                  onChange={(e) => setLast4DigitsOfPhoneNumber(e.target.value)}
                  onFocus={(e) => handleFocus("last_4_digits_of_phone_number", e)}
                  onBlur={() => set4DigitsPhoneNumberFieldTouched(true)}
                />
                <span className="text-danger mt-2">
                  {fourDigitsPhoneNumberFieldTouched &&
                  (last4DigitsOfPhoneNumber.length < 4 || /^\d{4}$/.test(last4DigitsOfPhoneNumber) === false)
                    ? "Please enter last 4 digits of your phone number"
                    : ""}
                </span>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  maxLength="12"
                  className="form-control form-control-lg"
                  placeholder="Unit No"
                  autoComplete="off"
                  name="unit_no"
                  value={unitNo}
                  onChange={(e) => setUnitNo(e.target.value)}
                  onFocus={(e) => handleFocus("unit_no", e)}
                />
              </div>
            </div>
            <div className="col-12 mt-5 text-center">
              <button
                name="submit"
                type="sumbit"
                className={`btn btn-danger btn-lg col-4 ${submitting ? "disabled" : ""}`}
                disabled={
                  submitting ||
                  !lastName ||
                  !unitNo ||
                  last4DigitsOfPhoneNumber.length < 4 ||
                  /^\d{4}$/.test(last4DigitsOfPhoneNumber) === false
                }
              >
                {submitting ? "Locating your account" : "Log In"}
              </button>
            </div>
          </div>
        </form>
      </div>
      <BackButton backPath="/home" />
    </div>
  );
}

export default TenantLogin;
