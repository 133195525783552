
import React, { useState } from "react";
import BackButton from "../../shared/BackButton";
import { setDefaultNotice } from "../../Utils/Common";
import { NavLink } from "react-router-dom";

function DefaultNotice(props) {
  const [option, setOption] = useState("");

  const handleClick = (e) => {
    setOption(e);
    setDefaultNotice(e);
  };

  return (
    <div className="wrapper wrapper-flex">
      <div className="container">
        <div className="row mt-5">
          <div className="col-sm-12 col-md-8 mx-auto">
            <h3 className="font-weight-bold text-center mb-4">You have the right to choose whether you want to receive any notice of default by mail or electronic mail. When choosing electronic mail, you waive any right to receive notice of default proceedings through personal service or mail.</h3>
            <div className="form-group mt-5">
              <button
                onClick={(e) => handleClick("mail")}
                className={option === "mail" ? "btn btn-lg btn-block btn-secondary" : "btn btn-lg btn-block btn-pink"}
              >
                Receive default notices by mail
              </button>
            </div>
            <div className="form-group mt-5">
            <button
                onClick={(e) => handleClick("email")}
                className={option === "email" ? "btn btn-lg btn-block btn-secondary" : "btn btn-lg btn-block btn-pink"}
              >
                Receive default notices by email
              </button>
            </div>
            <div className="row mt-4">
              {option !== "" && (
                <div className="col-12 text-right">
                  <NavLink
                    to={{
                      pathname: "/military",
                      state: {
                        prevPageUrl: "/default-notice",
                      },
                    }}
                    className="btn btn-pink btn-lg letter-spacing-2"
                  >
                    Next
                  </NavLink>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <BackButton backPath="/account-information" />
    </div>
  );
}

export default DefaultNotice;
