
import React, { useEffect } from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";

import BackButton from "../../shared/BackButton";
import { setInput, resetKeyboard } from "../../../redux/Keyboard/keyboard.action";
import { setLienHolderInfo } from "../../Utils/Common";

function LienHolderDetails(props) {
  const dispatch = useDispatch();

  const { currentInput, currentValue } = useSelector((state) => state.keyboard);

  useEffect(() => {
    dispatch(resetKeyboard(["lien_name", "phone_number", "address", "submit"]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    props.setFieldValue(currentInput, currentValue, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentInput, currentValue]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!props.isValid) {
      props.setTouched({ lien_name: true, phone_number: true, address: true });
      return;
    }

    setLienHolderInfo(props.values)
    dispatch(resetKeyboard([]));
    props.history.push("/insurance", { prevPageUrl: "/lien-holder-details" });
  };

  const handleFocus = (inputName, e) => {
    dispatch(setInput(inputName, e.target.value));
    setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 300);
  };

  return (
    <div className="wrapper wrapper-flex">
      <div className="container">
        <div className="row mt-5">
          <div className="col-sm-12 col-md-8 mx-auto">
            <h1 className="font-weight-bold text-center mb-4">Lienholder Details</h1>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <input
                      placeholder="Name *"
                      type="text"
                      name="lien_name"
                      className={`form-control form-control-lg ${currentInput === "lien_name" ? "focus" : ""}`}
                      value={props.values.lien_name}
                      onBlur={props.handleBlur}
                      onChange={props.handleChange}
                      onFocus={(e) => handleFocus("lien_name", e)}
                    />
                    <span className="text-danger">{props.touched.lien_name && props.errors.lien_name}</span>
                  </div>
                  <div className="form-group">
                    <input
                      placeholder="Phone Number *"
                      type="text"
                      name="phone_number"
                      className={`form-control form-control-lg ${currentInput === "phone_number" ? "focus" : ""}`}
                      value={props.values.phone_number}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      onFocus={(e) => handleFocus("phone_number", e)}
                    />
                    <span className="text-danger">{props.touched.phone_number && props.errors.phone_number}</span>
                  </div>
                  <div className="form-group">
                    <input
                      placeholder="Address"
                      type="text"
                      name="address"
                      className={`form-control form-control-lg ${currentInput === "address" ? "focus" : ""}`}
                      value={props.values.address}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      onFocus={(e) => handleFocus("address", e)}
                    />
                    <span className="text-danger">{props.touched.address && props.errors.address}</span>
                  </div>
                  <div className="form-group">
                    <textarea
                      rows="8"
                      className="form-control form-control-lg"
                      placeholder="Description *"
                      autoComplete="off"
                      name="description"
                      value={props.values.description}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      onFocus={() => handleFocus("description")}
                    />
                    <span className="text-danger">{props.touched.description && props.errors.description}</span>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-12 text-right">
                  <button
                    name="submit"
                    type="submit"
                    disabled={props.isSubmitting}
                    className="btn btn-pink btn-lg letter-spacing-2"
                  >
                    Next
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <BackButton backPath="/lien-holder" />
    </div>
  );
}

export default withFormik({
  mapPropsToValues: () => {
    return {
      lien_name: "",
      phone_number: "",
      description: "",
      address: "",
    };
  },
  validationSchema: Yup.object().shape({
    lien_name: Yup.string().required("Name required"),
    phone_number: Yup.string()
      .matches(/\d{10}/, "Phone number must be 10 digits")
      .required("Phone required"),
    address: Yup.string(),
    description: Yup.string().required("Description required"),
  }),
})(LienHolderDetails);
