import React, { useEffect } from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";

import BackButton from "../../shared/BackButton";
import { setInput, resetKeyboard } from "../../../redux/Keyboard/keyboard.action";
import { navigateToVehicleScreen, setMilitaryInfo } from "../../Utils/Common";

function MilitaryDetails(props) {
  const dispatch = useDispatch();

  const { currentInput, currentValue } = useSelector((state) => state.keyboard);

  useEffect(() => {
    dispatch(resetKeyboard(["military_branch", "military_base", "commanding_officer", "submit"]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    props.setFieldValue(currentInput, currentValue, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentInput, currentValue]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!props.isValid) {
      props.setTouched({ military_branch: true, military_base: true, commanding_officer: true });
      return;
    }

    setMilitaryInfo(props.values)
    dispatch(resetKeyboard([]));
    navigateToVehicleScreen(props);
  };

  const handleFocus = (inputName, e) => {
    dispatch(setInput(inputName, e.target.value));
    setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 300);
  };

  return (
    <div className="wrapper wrapper-flex">
      <div className="container">
        <div className="row mt-5">
          <div className="col-sm-12 col-md-8 mx-auto">
            <h1 className="font-weight-bold text-center mb-4">Active Military Information</h1>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <input
                      placeholder="Military Branch *"
                      type="text"
                      name="military_branch"
                      className={`form-control form-control-lg ${currentInput === "military_branch" ? "focus" : ""}`}
                      value={props.values.military_branch}
                      onBlur={props.handleBlur}
                      onChange={props.handleChange}
                      onFocus={(e) => handleFocus("military_branch", e)}
                    />
                    <span className="text-danger">{props.touched.military_branch && props.errors.military_branch}</span>
                  </div>
                  <div className="form-group">
                    <input
                      placeholder="Military Base *"
                      type="text"
                      name="military_base"
                      className={`form-control form-control-lg ${currentInput === "military_base" ? "focus" : ""}`}
                      value={props.values.military_base}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      onFocus={(e) => handleFocus("military_base", e)}
                    />
                    <span className="text-danger">{props.touched.military_base && props.errors.military_base}</span>
                  </div>
                  <div className="form-group">
                    <input
                      placeholder="Commanding Officer *"
                      type="text"
                      name="commanding_officer"
                      className={`form-control form-control-lg ${currentInput === "commanding_officer" ? "focus" : ""}`}
                      value={props.values.commanding_officer}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      onFocus={(e) => handleFocus("commanding_officer", e)}
                    />
                    <span className="text-danger">{props.touched.commanding_officer && props.errors.commanding_officer}</span>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-12 text-right">
                  <button
                    name="submit"
                    type="submit"
                    disabled={props.isSubmitting}
                    className="btn btn-pink btn-lg letter-spacing-2"
                  >
                    Next
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <BackButton backPath="/military" />
    </div>
  );
}

export default withFormik({
  mapPropsToValues: () => {
    return {
      military_branch: "",
      military_base: "",
      commanding_officer: "",
    };
  },
  validationSchema: Yup.object().shape({
    military_branch: Yup.string().required("Military Branch required"),
    military_base: Yup.string().required("Military Base required"),
    commanding_officer: Yup.string().required("Commanding Officer required"),
  }),
})(MilitaryDetails);
