import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import BackButton from "../../../shared/BackButton";
import { getPrevPageUrl } from "../../../Utils/Common";

function Vehicle(props) {
  const [vehicle, setVehicle] = useState("");

  return (
    <div className="wrapper wrapper-flex">
      <div className="container">
        <div className="row mt-5">
          <div className="col-sm-12 col-md-8 mx-auto">
            <h1 className="font-weight-bold text-center mb-4">Storing a Vehicle?</h1>
            <div className="form-group mt-5">
              <button
                onClick={(e) => setVehicle("yes")}
                className={vehicle === "yes" ? "btn btn-lg btn-block btn-secondary" : "btn btn-lg btn-block btn-pink"}
              >
                Yes
              </button>
            </div>
            <div className="form-group mt-5">
              <button
                onClick={(e) => setVehicle("no")}
                className={vehicle === "no" ? "btn btn-lg btn-block btn-secondary" : "btn btn-lg btn-block btn-pink"}
              >
                No
              </button>
            </div>
            <div className="row mt-4">
              {(vehicle === "yes" || vehicle === "no") && (
                <div className="col-12 text-right">
                  <NavLink
                    to={{
                      pathname: vehicle === "yes" ? "/vehicle-info" : "/lien-holder",
                      state: {
                        prevPageUrl: "/vehicle",
                      },
                    }}
                    className="btn btn-pink btn-lg letter-spacing-2"
                  >
                    Next
                  </NavLink>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <BackButton backPath={getPrevPageUrl("vehicle", props)} />
    </div>
  );
}

export default Vehicle;
