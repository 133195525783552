import axios from "axios";
// import { getTenantProfile } from "../Utils/data"; 
import { getTenantLeaseStatus, setTenantLeaseStatus } from "../Utils/Common";

export const validateTenantLeaseFiles = async (locationCode, tenantId, nextPath) => {
  setTenantLeaseStatus({
    required: true,
    tenant_id: tenantId,
    nextPath
  })
  window.location.replace("/complete-lease")

  // const res = await axios.get(`/api/tenants?location_code=${locationCode}&tenant_id=${tenantId}`);

  // let tenantData = res.data;

  // if (tenantData && tenantData.delinquency_tracker) {
  //   setTenantLeaseStatus({ required: false })
  //   window.location.replace(nextPath);
  // } else {
  //   if (!tenantData) {
  //     const tenantProfile = await getTenantProfile(locationCode, tenantId);

  //     const res2 = await axios.post("/api/tenants", {
  //       location_code: locationCode,
  //       tenant_id: tenantId,
  //       first_name: tenantProfile.first,
  //       last_name: tenantProfile.last,
  //       birthday: tenantProfile.date_of_birth.trim() === '' ? null : tenantProfile.date_of_birth.trim(),
  //     })
      
  //     tenantData = res2.data
  //   }
  //   setTenantLeaseStatus({
  //     required: true,
  //     kiwi_tenant_id: tenantData.id,
  //     tenant_id: tenantId,
  //     nextPath
  //   })

  //   window.location.replace("/complete-lease")
  // }
}

export const checkLeaseStatus = () => {
  const tenant = getTenantLeaseStatus();

  if (tenant && tenant.required) {
    window.location.replace("/complete-lease")
  }
}

export const saveTenantLeaseCompleted = async () => {
  const tenant = getTenantLeaseStatus();
  await axios.put(`/api/tenants/${tenant.kiwi_tenant_id}`, {
    delinquency_tracker: true,
  });
}
