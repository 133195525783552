
import React from "react";
import BackButton from "../../shared/BackButton";
import { getPrevPageUrl } from "../../Utils/Common";
import { setLienHolderInfo } from "../../Utils/Common";

function LienHolder(props) {
  const handleOption = async (value) => {
    if (value === "yes") {
      props.history.push("/lien-holder-details", { prevPageUrl: "/lien-holder" });
    } else {
      setLienHolderInfo(null);
      props.history.push("/insurance", { prevPageUrl: "/lien-holder" });
    }
  }

  return (
    <div className="wrapper wrapper-flex">
      <div className="container">
        <div className="row mt-5">
          <div className="col-sm-12 col-md-8 mx-auto">
            <h1 className="font-weight-bold text-center mb-4">Is There A Lienholder on Any of Your Contents?</h1>
            <div className="form-group mt-5">
              <button
                onClick={(e) => handleOption("yes")}
                className="btn btn-lg btn-block btn-pink"
              >
                Yes
              </button>
            </div>
            <div className="form-group mt-5">
              <button
                onClick={(e) => handleOption("no")}
                className="btn btn-lg btn-block btn-pink"
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
      <BackButton backPath={getPrevPageUrl("lien-holder", props)} />
    </div>
  );
}

export default LienHolder;
